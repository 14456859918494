import React from 'react';
import { Canvas } from '@react-three/fiber';
import { ACESFilmicToneMapping } from 'three';

interface ThreeCanvasProps {
  children?: React.ReactNode;
}

const ThreeCanvas: React.FC<ThreeCanvasProps> = ({ children }) => {
  return (
    <Canvas
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        pointerEvents: 'none', // Allow clicks to pass through
        background: 'transparent',
      }}
      camera={{
        position: [0, 0, 15],
        fov: 60,
        near: 0.1,
        far: 1000
      }}
      gl={{
        antialias: true,
        alpha: true,
        toneMapping: ACESFilmicToneMapping,
        toneMappingExposure: 1.5,
      }}
      legacy={false}
      dpr={[1, 2]} // Better rendering on high DPI screens
      eventSource={document.body} // Listen for events on the body instead of canvas
      eventPrefix="client" // Use clientX/Y instead of offsetX/Y
    >
      {/* Default lighting */}
      <ambientLight intensity={0.5} />
      <directionalLight position={[10, 10, 5]} intensity={0.5} />
      
      {/* Default camera setup - perspective camera is used by default */}
      
      {/* Render children (will be our ParticleSystem) */}
      {children}
    </Canvas>
  );
};

export default ThreeCanvas; 