import { Box, Divider, Typography, Link } from "@mui/material";
import React from "react";

const Footer = () => {
  return (<Box component="div" sx={{ backgroundColor: "background.paper" }} display="flex" flexDirection="row" justifyContent="center"
               alignContent="center" p={1} gap="5px" width="100vw" mt={2}>
    <Typography variant="subtitle2" color="info.main" align="center" justifyContent="center" alignContent="center">
      Contact me:{" "}
      <Link href="mailto:basic@basicbit.net" p={1}>
        basic@basicbit.net
      </Link>
    </Typography>
    <Divider orientation="vertical"/>
    <Typography variant="subtitle2" color="info.main" align="center" pl={1}>
      Avatar artwork by{" "}
      <Link href="https://x.com/soycatate" p={1}>
        SoyCatate
      </Link>
    </Typography>
  </Box>)
}

export default Footer;