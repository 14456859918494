import React, { useMemo, useRef, useEffect, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

interface ParticleSystemProps {
  particleCount?: number;
  speed?: number;
  interactive?: boolean;
  repulsionForce?: number;
  maxDistanceFromCursor?: number;
  colors?: string[];
  size?: number;
  opacity?: number;
}

// Perlin noise implementation (Improved Perlin noise)
const noise = (() => {
  const permutation = new Array(512);
  const p = new Array(256).fill(0).map((_, i) => i);
  
  // Fisher-Yates shuffle
  for (let i = p.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [p[i], p[j]] = [p[j], p[i]];
  }
  
  // Extend the permutation array to avoid overflow
  for (let i = 0; i < 512; i++) {
    permutation[i] = p[i & 255];
  }
  
  function fade(t: number) {
    return t * t * t * (t * (t * 6 - 15) + 10);
  }
  
  function lerp(t: number, a: number, b: number) {
    return a + t * (b - a);
  }
  
  function grad(hash: number, x: number, y: number, z: number) {
    const h = hash & 15;
    const u = h < 8 ? x : y;
    const v = h < 4 ? y : h === 12 || h === 14 ? x : z;
    return ((h & 1) === 0 ? u : -u) + ((h & 2) === 0 ? v : -v);
  }
  
  return function(x: number, y: number, z: number) {
    const X = Math.floor(x) & 255;
    const Y = Math.floor(y) & 255;
    const Z = Math.floor(z) & 255;
    
    x -= Math.floor(x);
    y -= Math.floor(y);
    z -= Math.floor(z);
    
    const u = fade(x);
    const v = fade(y);
    const w = fade(z);
    
    const A = permutation[X] + Y;
    const AA = permutation[A] + Z;
    const AB = permutation[A + 1] + Z;
    const B = permutation[X + 1] + Y;
    const BA = permutation[B] + Z;
    const BB = permutation[B + 1] + Z;
    
    return lerp(w,
      lerp(v,
        lerp(u,
          grad(permutation[AA], x, y, z),
          grad(permutation[BA], x - 1, y, z)
        ),
        lerp(u,
          grad(permutation[AB], x, y - 1, z),
          grad(permutation[BB], x - 1, y - 1, z)
        )
      ),
      lerp(v,
        lerp(u,
          grad(permutation[AA + 1], x, y, z - 1),
          grad(permutation[BA + 1], x - 1, y, z - 1)
        ),
        lerp(u,
          grad(permutation[AB + 1], x, y - 1, z - 1),
          grad(permutation[BB + 1], x - 1, y - 1, z - 1)
        )
      )
    );
  };
})();

const PLANE_WIDTH = 40; // Standardized plane width for the entire component
const MAX_DEPTH = 20;

const ParticleSystem: React.FC<ParticleSystemProps> = ({ 
  particleCount: propParticleCount = 2000,
  speed = 0.2,
  interactive = true,
  repulsionForce = 0.15,
  maxDistanceFromCursor = 2,
  colors: colorStrings = ['#8ecae6', '#219ebc', '#023047'],
  size = 0.02,
  opacity = 0.6
}) => {
  // Adjust particle count based on screen size
  const [particleCount, setParticleCount] = useState(propParticleCount);
  
  // Reference to the InstancedMesh
  const instances = useRef<THREE.InstancedMesh>(null);
  
  // Get the current state of the canvas
  useThree();
  
  // Track mouse position
  const [mousePosition, setMousePosition] = useState<THREE.Vector3 | null>(null);
  
  // Handle responsive particle count
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 600) {
        setParticleCount(Math.floor(propParticleCount * 0.5));
      } else if (width < 1024) {
        setParticleCount(Math.floor(propParticleCount * 0.75));
      } else {
        setParticleCount(propParticleCount);
      }
    };
    
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [propParticleCount]);
  
  // Create geometry and materials
  const [geometry, material] = useMemo(() => {
    const geo = new THREE.SphereGeometry(1, 12, 12);
    const mat = new THREE.MeshPhysicalMaterial({
      transparent: true,
      opacity: opacity * 0.8,
      depthWrite: false,
      blending: THREE.AdditiveBlending,
      metalness: 0.8,
      roughness: 0.2,
      clearcoat: 1.0,
      clearcoatRoughness: 0.2,
      reflectivity: 1.0,
      ior: 2.5,
      transmission: 0.95,
      specularIntensity: 4.0,
      specularColor: new THREE.Color(0xffffff),
      envMapIntensity: 2.0,
      side: THREE.DoubleSide,
    });
    return [geo, mat];
  }, [opacity]);
  
  // Initialize positions, matrices, and colors
  const [positions,, colorArray, particleStates] = useMemo(() => {
    const pos = new Float32Array(particleCount * 3);
    const mats = new Array(particleCount).fill(0).map(() => new THREE.Matrix4());
    const cols = new Float32Array(particleCount * 3);
    const states = new Array(particleCount).fill(0); // 0: wave, 1: near-escape, 2: far-escape
    const opacities = new Float32Array(particleCount);

    const lineWidth = 0.5;
    const farEscapeChance = 0.3;
    const nearEscapeChance = 0.2;
    
    // Camera distance parameters
    const baseZ = 0;
    const lineDepth = 8;
    const nearEscapeDepth = 3;
    const farEscapeDepth = 8;
    const maxDepth = farEscapeDepth;

    // Convert colors to THREE.Color objects
    const colorObjects = colorStrings.map(color => new THREE.Color(color));

    for (let i = 0; i < particleCount; i++) {
      const rand = Math.random();
      if (rand < farEscapeChance) {
        states[i] = 2; // far escape
      } else if (rand < farEscapeChance + nearEscapeChance) {
        states[i] = 1; // near escape
      } else {
        states[i] = 0; // wave
      }

      let x, y, z;
      if (states[i] === 2) { // far escaped
        x = (Math.random() - 0.5) * PLANE_WIDTH * 1.5;
        y = (Math.random() - 0.5) * 8;
        z = baseZ + Math.random() * farEscapeDepth;
      } else if (states[i] === 1) { // near escaped
        x = (Math.random() - 0.5) * PLANE_WIDTH * 1.2;
        y = (Math.random() - 0.5) * 4;
        z = baseZ + Math.random() * nearEscapeDepth;
      } else { // wave
        x = (Math.random() - 0.5) * PLANE_WIDTH;
        y = (Math.random() - 0.5) * lineWidth;
        z = baseZ + Math.random() * lineDepth;
      }

      pos[i * 3] = x;
      pos[i * 3 + 1] = y;
      pos[i * 3 + 2] = z;

      // Enhanced depth-based opacity
      const depthFactor = Math.pow(1 - (z - baseZ) / maxDepth, 2); // Squared for stronger falloff
      opacities[i] = opacity * (0.2 + depthFactor * 0.8); // Vary between 20% and 100% of base opacity

      // Assign colors with state-based selection
      const color = states[i] === 2 
        ? colorObjects[2] // Darkest color for far escaped
        : states[i] === 1
          ? colorObjects[1] // Middle color for near escaped
          : colorObjects[0]; // Lightest color for wave
      
      cols[i * 3] = color.r;
      cols[i * 3 + 1] = color.g;
      cols[i * 3 + 2] = color.b;

      // Enhanced depth-based size adjustment
      const depthSize = Math.pow(1 - (z - baseZ) / maxDepth, 1.5);
      const stateSize = states[i] === 2 ? 0.6 : states[i] === 1 ? 0.8 : 1;
      const particleSize = size * stateSize * depthSize;
      mats[i].makeScale(particleSize, particleSize, particleSize);
      mats[i].setPosition(x, y, z);
    }

    return [pos, mats, cols, states, opacities];
  }, [particleCount, size, colorStrings, opacity]);
  
  // Effect to set colors after mesh is created
  useEffect(() => {
    if (instances.current) {
      instances.current.instanceColor = new THREE.InstancedBufferAttribute(colorArray, 3);
    }
  }, [colorArray]);
  
  // Handle mouse movement
  useEffect(() => {
    if (!interactive) return;
    
    const handlePointerMove = (event: PointerEvent) => {
      // Convert screen coordinates to -1 to 1 range
      const x = (event.clientX / window.innerWidth) * 2 - 1;
      const y = -(event.clientY / window.innerHeight) * 2 + 1;
      
      // Scale to match our particle space
      const mouseX = x * (PLANE_WIDTH / 2);
      const mouseY = y * (PLANE_WIDTH / 2 * (window.innerHeight / window.innerWidth));
      
      setMousePosition(new THREE.Vector3(mouseX, mouseY, 0));
    };
    
    window.addEventListener('pointermove', handlePointerMove);
    return () => window.removeEventListener('pointermove', handlePointerMove);
  }, [interactive]);
  
  // Light animation refs
  const movingLight1 = useRef<THREE.PointLight>(null);
  const movingLight2 = useRef<THREE.PointLight>(null);
  const movingLight3 = useRef<THREE.PointLight>(null);

  // Animation loop
  useFrame(({ clock }) => {
    if (!instances.current) return;

    const time = clock.getElapsedTime();
    const matrix = new THREE.Matrix4();
    const waveFrequency = 2;
    const waveAmplitude = 2;
    const noiseScale = 0.1;
    const noiseStrength = 0.8;
    const waveStrength = 0.5;

    // Animate first moving light in a more dynamic circular pattern
    if (movingLight1.current) {
      const radius = PLANE_WIDTH * 0.4; // 16 units radius
      movingLight1.current.position.x = Math.sin(time * 0.4) * radius;
      movingLight1.current.position.z = Math.cos(time * 0.4) * radius - MAX_DEPTH * 0.5;
      movingLight1.current.position.y = Math.sin(time * 0.3) * 8;
      movingLight1.current.intensity = 4 + Math.sin(time) * 2;
      movingLight1.current.color.setHSL(0.6 + Math.sin(time * 0.2) * 0.1, 0.8, 0.5);
    }

    // Animate second moving light in a complex figure-8 pattern
    if (movingLight2.current) {
      const scaleX = PLANE_WIDTH * 0.3; // 12 units
      const scaleY = 10;
      movingLight2.current.position.x = Math.sin(time * 0.5) * scaleX;
      movingLight2.current.position.y = Math.sin(time * 1) * scaleY;
      movingLight2.current.position.z = -MAX_DEPTH * 0.3 + Math.cos(time * 0.7) * 5;
      movingLight2.current.intensity = 3.5 + Math.sin(time * 1.5) * 1.5;
      movingLight2.current.color.setHSL(0.9 + Math.sin(time * 0.3) * 0.1, 0.9, 0.5);
    }

    // Animate third moving light in a spiral pattern
    if (movingLight3.current) {
      const radius = PLANE_WIDTH * 0.35; // 14 units
      const angle = time * 0.6;
      movingLight3.current.position.x = Math.sin(angle) * radius;
      movingLight3.current.position.z = Math.cos(angle) * radius - MAX_DEPTH * 0.7;
      movingLight3.current.position.y = Math.sin(time * 0.4) * 12;
      movingLight3.current.intensity = 3 + Math.cos(time * 0.7) * 1.5;
      movingLight3.current.color.setHSL(0.2 + Math.sin(time * 0.4) * 0.1, 0.8, 0.5);
    }

    for (let i = 0; i < particleCount; i++) {
      const i3 = i * 3;
      const baseX = positions[i3];
      const baseY = positions[i3 + 1];
      const particleBaseZ = positions[i3 + 2];

      let finalX = baseX;
      let finalY = baseY;
      let finalZ = particleBaseZ;

      const noiseTime = time * speed;
      const normalizedX = baseX / (PLANE_WIDTH * 0.5);
      const waveOffset = normalizedX * waveFrequency * Math.PI * 2 + time * speed;

      // Adjust noise and wave influence based on particle state
      const noiseForceX = noise(baseX * noiseScale, baseY * noiseScale, noiseTime) * noiseStrength;
      const noiseForceY = noise(baseY * noiseScale, particleBaseZ * noiseScale, noiseTime) * noiseStrength;
      const noiseForceZ = noise(particleBaseZ * noiseScale, baseX * noiseScale, noiseTime) * (noiseStrength * 0.5);
      const waveForceY = Math.sin(waveOffset) * waveAmplitude * waveStrength;

      if (particleStates[i] === 2) { // far escaped
        finalX += noiseForceX * 2;
        finalY += noiseForceY * 2;
        finalZ += noiseForceZ * 2;
      } else if (particleStates[i] === 1) { // near escaped
        finalX += noiseForceX * 1.5;
        finalY += noiseForceY * 1.5 + waveForceY * 0.3;
        finalZ += noiseForceZ * 1.5;
      } else { // wave
        finalX += noiseForceX * 0.5;
        finalY += noiseForceY * 0.5 + waveForceY;
        finalZ += noiseForceZ * 0.5;
      }

      // Apply mouse repulsion
      if (interactive && mousePosition) {
        const particlePos = new THREE.Vector3(finalX, finalY, finalZ);
        const toMouse = mousePosition.clone().sub(particlePos);
        const distance = toMouse.length();
        
        if (distance < maxDistanceFromCursor) {
          // Scale force based on particle state and distance
          let stateForceScale = particleStates[i] === 2 ? 0.3 : // far escaped particles affected less
                               particleStates[i] === 1 ? 0.6 : // near escaped particles affected moderately
                               1.0; // wave particles affected fully
          
          const distanceFactor = 1 - (distance / maxDistanceFromCursor);
          const forceMagnitude = repulsionForce * stateForceScale * distanceFactor;
          
          const repulsionForceVector = toMouse.normalize().multiplyScalar(-forceMagnitude);
          
          // Scale Z-component based on distance from wave plane
          const zDistanceScale = 1 / (1 + Math.abs(finalZ) * 0.2);
          
          finalX += repulsionForceVector.x;
          finalY += repulsionForceVector.y;
          finalZ += repulsionForceVector.z * zDistanceScale * 0.3;
        }
      }

      // Enhanced depth-based effects
      const depthSize = Math.pow(1 - (finalZ / MAX_DEPTH), 1.5);
      const stateSize = particleStates[i] === 2 ? 0.6 : particleStates[i] === 1 ? 0.8 : 1;
      const particleSize = size * stateSize * depthSize;

      matrix.makeScale(particleSize, particleSize, particleSize);
      matrix.setPosition(finalX, finalY, finalZ);
      instances.current.setMatrixAt(i, matrix);
    }

    instances.current.instanceMatrix.needsUpdate = true;
  });
  
  return (
    <>
      {/* Base ambient light for overall illumination */}
      <ambientLight intensity={0.02} color="#223344" />

      {/* Main directional light for primary illumination */}
      <directionalLight 
        position={[PLANE_WIDTH * 0.2, 10, -5]} 
        intensity={0.3} 
        color="#4488ff"
      />

      {/* Animated point lights for dynamic effects */}
      <pointLight
        ref={movingLight1}
        position={[15, 0, -10]}
        intensity={4}
        color="#4cc9f0"
        distance={60}
        decay={0.5}
        power={120}
      />
      <pointLight
        ref={movingLight2}
        position={[0, 10, -5]}
        intensity={3.5}
        color="#f72585"
        distance={55}
        decay={0.5}
        power={100}
      />
      <pointLight
        ref={movingLight3}
        position={[-15, 0, -15]}
        intensity={3}
        color="#4361ee"
        distance={50}
        decay={0.5}
        power={90}
      />

      {/* Static accent lights for depth */}
      <pointLight
        position={[0, -10, -8]}
        intensity={2}
        color="#7209b7"
        distance={45}
        decay={1.5}
        power={80}
      />
      <pointLight
        position={[0, 10, -8]}
        intensity={2.2}
        color="#3a0ca3"
        distance={45}
        decay={1.5}
        power={80}
      />

      {/* Additional rim lights for extra pop */}
      <pointLight
        position={[PLANE_WIDTH * 0.4, 0, -12]}
        intensity={2.5}
        color="#ff9e00"
        distance={50}
        decay={1.5}
        power={70}
      />
      <pointLight
        position={[-PLANE_WIDTH * 0.4, 0, -12]}
        intensity={2.5}
        color="#00b4d8"
        distance={50}
        decay={1.5}
        power={70}
      />

      <instancedMesh
        ref={instances}
        args={[geometry, material, particleCount]}
        frustumCulled={false}
      />
    </>
  );
};

export default ParticleSystem; 