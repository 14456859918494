import React from "react";
import Projects from "./Projects";
import About from "./About";
import DJSchedule from "./DJSchedule";
import { Divider } from "@mui/material";

const Home: React.FC = () => {
  return (
    <>
      <About />
      <Divider flexItem sx={{my: 4}} data-testid="home-page"/>
      <Projects />
      <DJSchedule />
    </>
  );
}

export default Home;