import React from 'react';
import {
  Box,
  useTheme,
  useMediaQuery,
  Paper,
  Typography,
} from "@mui/material";

interface DJScheduleProps {
  // Define any props here if needed
}

const DJSchedule: React.FC<DJScheduleProps> = (props) => {
  const theme = useTheme();
  
  const largeView = useMediaQuery(theme.breakpoints.up('md'));

  const extraLarge = useMediaQuery(theme.breakpoints.up('xl'));

  // Adjust width and other properties based on screen size
  const calendarWidth = extraLarge ? 400 : largeView ? 300 : '100%';
  const calendarHeight = extraLarge ? 400 : largeView ? 300 : 300;

  const styling = largeView ? { 
    position: 'absolute',
    top: largeView ? '20px' : '10px',
    right: largeView ? '20px' : '10px',
    zIndex: 100,
    transition: 'all 0.25s ease',
    opacity: 0.8,
    boxShadow: theme.shadows[3],
    borderRadius: 2,
  } as const : {
    borderRadius: 2,
    boxShadow: theme.shadows[3],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '400px',
    opacity: 0.9,
  } as const
  
  return (
    <Box 
      component="div"
      sx={styling}
    >
      <Paper 
        component="div"
        elevation={0}
        sx={{ 
          overflow: 'hidden',
          borderRadius: 2,
          backdropFilter: 'blur(5px)',
          width: '100%',
        }}
      >
        <Box 
          component="div"
          sx={{ 
            p: 1, 
            width: '100%',
          }}
        >
          <Typography variant="subtitle1" fontWeight="500" align="center">
            DJ Schedule
          </Typography>
        </Box>
        <Box 
          component="iframe" 
          src={`https://calendar.google.com/calendar/embed?bgcolor=%23FF0000&height=${calendarHeight}&wkst=1&ctz=America%2FIndiana%2FIndianapolis&showPrint=0&mode=AGENDA&showCalendars=0&showTz=0&title=BASICBIT%20DJ%20Schedule&showNav=0&showTabs=0&showDate=0&showTitle=0&src=NzZiOTBkZGQ5NDIwMTJkODdhZjAzMDFkZjc0MWMzNTBmMTliYjVmODM0MzUxZDEwMTU3MDZlNGI0ZjZlZGEzZEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%233F51B5`}
          width={calendarWidth}
          height={calendarHeight}
          frameBorder="0" 
          scrolling="no"
          sx={{ 
            border: 'none',
            display: 'block',
            borderRadius: 4,
          }}
        />
      </Paper>
    </Box>
  );
};

export default DJSchedule;