import React from 'react';
import './App.scss';
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import colors from './styles/_colors.module.scss';
import { Route, Routes } from "react-router-dom";
import RefHandler from "./RefHandler";
import PageContainer from "./PageContainer";
import Home from "./Home";
import {Comms} from "./Comms";
import ParticleBackground from './components/particleSystem/ParticleBackground';

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    mode: "dark",
    primary: {
      main: colors.shine,
    },
    secondary: {
      main: colors.secondary,
    },
    info: {
      main: colors.info,
    },
    warning: {
      main: colors.warning,
    },
    background: {
      default: colors.void,
      paper: colors.metal,
    },
    text: {
      primary: colors.star,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ParticleBackground />
      <PageContainer>
        {/*<Navbar />*/}
        <Routes>
          <Route path="/vrc" element={<RefHandler type="vrc" />} />
          <Route path="/discord" element={<RefHandler type="discord" />} />
          <Route path="/" element={<Home />} />
          <Route path="/comms" element={<Comms />} />
        </Routes>
      </PageContainer>
    </ThemeProvider>
  );
}

export default App;
