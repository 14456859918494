import React from 'react';
import ThreeCanvas from './ThreeCanvas';
import ParticleSystem from './ParticleSystem';

interface ParticleBackgroundProps {
  particleCount?: number;
  speed?: number;
  interactive?: boolean;
  repulsionForce?: number;
  maxDistanceFromCursor?: number;
  colors?: string[];
  size?: number;
  opacity?: number;
}

const ParticleBackground: React.FC<ParticleBackgroundProps> = ({ 
  particleCount = 8000,
  speed = 0.2,
  interactive = true,
  repulsionForce = 0.07,
  maxDistanceFromCursor = 100,
  colors = ['#8ecae6', '#219ebc', '#023047'],
  size = 0.03,
  opacity = 0.8
}) => {
  return (
    <div style={{ 
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      zIndex: 0, // Ensure it stays behind content
      pointerEvents: 'none', // Allow clicks to pass through to content
    }}>
      <ThreeCanvas>
        <ParticleSystem 
          particleCount={particleCount}
          speed={speed}
          interactive={interactive}
          repulsionForce={repulsionForce}
          maxDistanceFromCursor={maxDistanceFromCursor}
          colors={colors}
          size={size}
          opacity={opacity}
        />
      </ThreeCanvas>
    </div>
  );
};

export default ParticleBackground; 