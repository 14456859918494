import {Box, Link, Stack, Typography} from "@mui/material";
import React from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {Link as RouterLink} from "react-router-dom";


export const Comms = () => {
    return (
        <Stack direction="column">
            <Typography variant="h1" sx={{alignSelf: 'center'}}>Commissioned Art</Typography>
            <Stack direction="column" sx={{gap: 15, maxWidth: '100vw', px: 20, py: 5}}>
                <Stack direction="column" sx={{gap: 2}}>
                    <img src="/img/comm1.png" style={{ borderRadius: 15 }} alt="Commissioned Art by SoyCatate" />
                    <Typography variant="subtitle2" color="info.main" align="center" pl={1}>
                        Artwork by{" "}
                        <Link href="https://x.com/soycatate" p={1}>
                            SoyCatate
                        </Link>
                    </Typography>
                </Stack>
                <Stack direction="column" sx={{gap: 2}}>
                    <img src="/img/comm2.jpg" style={{ borderRadius: 15 }} alt="Commissioned Art by BambYllie"/>
                    <Typography variant="subtitle2" color="info.main" align="center" pl={1}>
                        Artwork by{" "}
                        <Link href="https://x.com/bambi_lie0703" p={1}>
                            BambYllie
                        </Link>
                    </Typography>
                </Stack>
                <Stack direction="column" sx={{gap: 2}}>
                    <video src="/img/comm3.mp4" autoPlay={true} controls={false} muted={true} style={{ borderRadius: 15 }}  />
                    <Typography variant="subtitle2" color="info.main" align="center" pl={1}>
                        Artwork by{" "}
                        <Link href="https://vgen.co/Nynbug" p={1}>
                            Nynbug
                        </Link>
                    </Typography>
                </Stack>
            </Stack>
            <Box component="div"
                sx={{
                    position: 'fixed',
                    top: 50,
                    left: 50,
                    padding: 2,
                    border: '2 solid #FFFFFF',
                    zIndex: 1000, // Ensure it's above other content
                    borderRadius: '50%',
                }}
            >
                <RouterLink to="/">
                    <ArrowBackIosNewIcon color="primary" />
                </RouterLink>
            </Box>
        </Stack>
    )
};
