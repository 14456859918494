import React from "react";
import { Box, Grid } from "@mui/material";
import Footer from "./Footer";

const PageContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Box component="div" sx={{ 
      flexGrow: 1,
      position: 'relative',
      zIndex: 1, // Ensure content is above particle system
    }}>
      <Grid 
        display="flex" 
        direction="column" 
        height="100vh" 
        width="100vw" 
        sx={{ 
          overflowX: "hidden",
          position: 'relative', // Ensure proper stacking context
        }}
      >
        <Box component="div" sx={{
          pt: 5,
          pl: 4,
          pr: 4,
          pb: 2,
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: "100vw",
          flexGrow: 1,
          position: 'relative', // Ensure proper stacking context
        }}>
          {children}
        </Box>
        <Footer/>
      </Grid>
    </Box>
  );
};

export default PageContainer;
